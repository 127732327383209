import axios from "@/plugins/axios";
import { isAxiosError, isSystemError } from "@/plugins/Error";
import { ComboboxChildOption, isNotUpload, PageResult, SearchService } from ".";

const ProjectPaths = {
  List: "api/Project",
  Get: (id: string, isForce?: boolean) =>
    `api/Project/${id}?isAll=true${isForce === true ? "&isForce=true" : ""}`,
  Delete: (id: string) => `api/Project/${id}`,
  Save: (id: string) => `api/Project/${id}`,
  CloudUpload: (id: string) => `api/Project/${id}/synchronize`,
  TemplateList: "api/ReportTemplate/combobox",
  Template: (id: string) => `api/ReportTemplate/${id}`,
  TemplateFileValidate: (id: string) => `api/ReportTemplate/${id}/validate`,
  TemplateFileValidateByProject: (id: string) =>
    `api/ReportTemplate/${id}/validate?isProject=true`,
  Post: "api/Project",
};

export type SearchParams = {
  name: string;
  isUploaded: string;
  templateId: string;
  orderBy: string;
};

export type TemplateModel = {
  id: string;
  equipmentId: string;
  fileName: string;
  filePath: string;
  useAdditional: boolean;
  isProject: boolean;
  createAt: string;
  createUserId: string;
  updateAt: string;
  updateUserId: string;
  isDeleted: boolean;
};

export type ProjectModel = {
  id: string;
  name: string;
  start: string;
  end: string;
  templateId: string | null;
  updateAt: string;
  updateUserId: string | null;
  uploadAt: string;
  createAt: string;
  createUserId: string | null;
  template: TemplateModel;
};
export type SearchResult = {
  list: ProjectModel[];
  page: PageResult;
};
export class ProjectService extends SearchService<SearchParams, ProjectModel> {
  constructor() {
    super({ omit: ["detail"] });
  }
  protected get getPath(): string {
    return ProjectPaths.List;
  }

  /**
   * combobox としての テンプレートの取得
   * @param option オプション
   * @returns
   */
  async getTemplates(option?: {
    isAll?: string;
    isProject?: boolean;
    equipmentId?: string;
  }): Promise<ComboboxChildOption[]> {
    try {
      const record = (option ?? {}) as Record<string, string>;
      record.hasParent = "true";
      const query = new URLSearchParams(record).toString();
      const response = await axios.get<ComboboxChildOption[]>(
        `${ProjectPaths.TemplateList}?${query}`
      );
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  /**
   * テンプレートの取得
   * @param templateId
   * @returns
   */
  async getTemplate(templateId: string): Promise<TemplateModel> {
    try {
      const url = ProjectPaths.Template(templateId);
      const response = await axios.get<TemplateModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async validateTemplate(
    templateId: string,
    isProject: boolean
  ): Promise<"ok" | "ng" | "notFound"> {
    try {
      const url = isProject
        ? ProjectPaths.TemplateFileValidateByProject(templateId)
        : ProjectPaths.TemplateFileValidate(templateId);
      await axios.get<void>(url);
      return "ok";
    } catch (e) {
      console.error(e);
      if (!isAxiosError(e)) throw e;
      if (!e.response?.status) throw e;
      if (e.response?.status == 404) return "notFound";
      return "ng";
    }
  }
  async cloudUpload(id: string): Promise<boolean> {
    try {
      const url = ProjectPaths.CloudUpload(id);
      await axios.post(url);
      return true;
    } catch (e) {
      console.error(e);
      if (isSystemError(e)) throw e;
      return false;
    }
  }
  async get(id: string, isForce?: boolean): Promise<ProjectModel> {
    try {
      const url = ProjectPaths.Get(id, isForce);
      const response = await axios.get<ProjectModel>(url);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async save(model: ProjectModel): Promise<void> {
    try {
      const url = ProjectPaths.Save(model.id);
      await axios.put(url, model);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async delete(id: string): Promise<void> {
    try {
      const url = ProjectPaths.Delete(id);
      await axios.delete(url);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
  async post(name: string, templateId: string): Promise<ProjectModel> {
    try {
      const form = new FormData();
      form.append("name", name);
      form.append("templateId", templateId);
      const result = await axios.post<ProjectModel>(
        `./${ProjectPaths.Post}`,
        form
      );
      return result.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
}
export const Project = new ProjectService();

/**
 * アップロード可否
 * @param model
 * @returns
 */
export function isUploadable(model: ProjectModel): boolean {
  const uploadAt = model.uploadAt;
  // 未アップロードならアップロード可
  if (uploadAt == null) return true;
  // アップロード日 が 更新日 よりも前
  return isNotUpload(model);
}
