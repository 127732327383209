/**
 * ファイル名から拡張子を抜いたものを取得する
 * @param filename ファイル名
 * @returns
 */
export function getFilenameWithoutExtension(
  filename?: string
): string | undefined {
  if (filename == null || filename.length == 0) return filename;
  const base = new String(filename).substring(filename.lastIndexOf("/") + 1);
  const lastIndexOf = base.lastIndexOf(".");
  if (lastIndexOf != -1) return base.substring(0, lastIndexOf);
  return base;
}
/**
 * ファイル名から拡張子を取得する
 * @param filename ファイル名
 * @returns
 */
export function getExtension(filename?: string): string | undefined {
  if (filename == null || filename.length == 0) return filename;
  const base = new String(filename).substring(filename.lastIndexOf("/") + 1);
  const lastIndexOf = base.lastIndexOf(".");
  if (lastIndexOf == -1) return "";
  return base.substring(lastIndexOf);
}
